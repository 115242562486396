@import './normalize.css';

:root {
    --color-primary: #907C68;
    --color-secondary: #6B6051;
    --color-accent: #BFA086;
    --color-headings: #5A4B3A;
    --color-body: #A8967F;
    --color-body-darker: #78655B;
    --color-background: #F5EFE4;
    --color-border: #ccc;
    --border-radius: 30px;
    --padding-vertical: 2rem;
    --border-radius-block: 0px;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

/* Typography */

::selection {
    background: var(--color-primary);
    color: #fff;
}

html {
    font-size: 62.5%;
}

body {
    font-family: Rokkitt, Arial, Helvetica, sans-serif;
    font-size: 2.4rem;
    line-height: 1.5;
    color: var(--color-body);
}

h1,
h2,
h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
}

h1 {
    font-size: 6rem;
    font-weight: 600;
}

h2 {
    font-size: 3rem;
    font-weight: 500;
}

h3 {
    font-size: 2rem;
    font-weight: 400;
}

p {
    margin-top: 0;
}

@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }

    h1 {
        font-size: 8rem;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2.4rem;
    }
}

/* Links */

a {
    text-decoration: none;
}

.link {
    color: var(--color-body-darker);
    font-size: 2rem;
    font-weight: 500;
}

.link--white {
    color: white;
    font-size: 1.6rem;
    font-weight: 400;
}

.link-arrow {
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
}

.link-arrow::after {
    content: '>>';
    margin-left: 5px;
    transition: margin 0.15s;
}

.link-arrow:hover::after {
    margin-left: 10px;
}

@media screen and (min-width: 1024px) {

    .link-arrow {
        font-size: 1.5rem;
    }
}

/* Lists */

.list {
    list-style: none;
    padding-left: 0;
}

.list--inline .list__item {
    display: inline-block;
    margin-right: 2rem;
}

.list--point {
    list-style: disc;
}

.list--point .list__item {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
    .list--point .list__item {
        padding-left: 0;
    }
}

/* Icons */

.icon {
    width: 40px;
    height: 40px;
}

.icon--small {
    width: 30px;
    height: 30px;
}

.icon--primary {
    fill: var(--color-primary);
}

.icon--white {
    fill: #fff;
}

.icon-container {
    background: #f3f9fa;
    border-radius: 100%;
    display: inline-flex;
    height: 64px;
    width: 64px;
    justify-content: center;
    align-items: center;
}

.icon-container--accent {
    background: var(--color-accent);
}

/* Buttons */

.btn {
    border-radius: var(--border-radius);
    border: 0;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0;
    outline: 0;
    padding: 2rem 4vw;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.btn .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.btn--primary {
    background: var(--color-primary);
    color: #fff;
}

.btn--primary:hover {
    background: var(--color-accent);
}

.btn--secondary {
    background: var(--color-secondary);
    color: #fff;
}

.btn--secondary:hover {
    background: var(--color-accent);
}

.btn--accent {
    background: var(--color-accent);
    color: #fff;
}

.btn--accent:hover {
    background: var(--color-primary);
}

.btn--outline {
    background: #fff;
    color: var(--color-headings);
    border: 2px solid var(--color-headings);
}

.btn--outline:hover {
    background: var(--color-headings);
    color: #fff;
}

.btn--streched {
    padding-left: 6rem;
    padding-right: 6rem;
}

.btn--block {
    width: 100%;
    display: inline-block;
}

@media screen and (min-width: 1024px) {
    .btn {
        font-size: 1.5rem;
    }
}

/* Inputs */

.input {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    color: var(--color-headings);
    font-size: 2rem;
    outline: 0;
    padding: 1.5rem 3.5rem;
}

::placeholder {
    color: #cdcbd7
}

.input-group {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    display: flex;
}

.input-group .input {
    border: 0;
    flex-grow: 1;
    padding: 1.5rem 2rem;
    width: 0;
}

.input-group .btn {
    margin: 4px;
}

@media screen and (min-width: 1024px) {
    .input {
        font-size: 1.5rem;
    }
}

/* Media */

.media {
    display: flex;
    margin-bottom: 4rem;
}

.media--nav {
    max-width: 5rem;
}

.media__title {
    margin-top: 0;
}

.media__body {
    margin: 0 2rem;
}

.media__image {
    margin-top: 1rem;
}

/* Quotes */

.quote {
    font-size: 2.5rem;
    font-style: italic;
    color: var(--color-body-darker);
    line-height: 1.3;
    margin: 10px;
}

.quote__text::before {
    content: open-quote;
}

.quote__text::after {
    content: close-quote;
}

.quote__author {
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
}

.quote__organization {
    color: var(--color-headings);
    font-size: 2rem;
    font-style: normal;
    opacity: .4;
    margin-bottom: 0;
}

.quote__line {
    position: relative;
    bottom: 15px;
}

@media screen and (min-width: 1024px) {
    .quote {
        font-size: 2rem;
    }

    .quote__author {
        font-size: 2.4rem;
    }

    .quote__organization {
        font-size: 1.6rem;
    }

}

/* Grids */

.grid {
    display: grid;
}

@media screen and (min-width: 768px) {
    .grid--1x2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1024px) {
    .grid--1x3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Collapsible */

.collapsible__header {
    display: flex;
    justify-content: space-between;
}

.collapsible__heading {
    margin-top: 0;
    font-size: 3rem;
}

.collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.collapsible__chevron {
    transform: rotate(-90deg);
    transition: transform 0.3s;
}

.collapsible--expanded .collapsible__chevron {
    transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
    display: block;
    max-height: 100vh;
    opacity: 1;
}

/* Blocks */

.block {
    padding: var(--padding-vertical) 2rem;
}

.block__header {
    text-align: center;
    margin-bottom: 4rem;
}

.block__heading {
    margin-top: 0;
}

.block--dark {
    background: #000;
    color: #7b858b;
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
    color: #fff;
}

.block--skewed-right {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
}

.block--skewed-left {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

.container,
.hero {
    max-width: 1140px;

}

.container {
    margin: 0 2rem;
}

.hero {
    margin: 0;
}

@media screen and (min-width: 768px) {
    :root {
        --padding-vertical: 4rem;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --padding-vertical: 8rem;
    }

    .container,
    .hero {
        margin: 0 auto;
    }
}

/* Brand */

.brand {
    display: flex;
    margin: 1rem;
}

.brand__image {
    height: 5rem;
    align-self: center;
}

.brand__body {
    align-self: center;
    margin: 1rem;
    color: var(--color-secondary);
}

.brand__heading {
    font-size: 2rem;
}

.brand__text {
    font-size: 1.5rem;
}

.brand__heading,
.brand__text {
    margin: 0;
}

.brand:hover .brand__heading,
.brand:hover .brand__text {
    color: var(--color-accent);
    transition: color 0.3s;
}

.brand:hover .brand__image {
    transform: scale(1.1);
    transition: transform 0.3s;
}

@media screen and (min-width: 768px) {
    .brand__image {
        height: 5rem;
    }

    .brand__heading {
        font-size: 2.2rem;
    }

    .brand__text {
        font-size: 2rem;
    }
}

/* Features */

.feature {
    gap: 2rem 2rem;
    margin: 0;
}

.feature__content {
    text-align: center;
    padding: 2rem 0;
}

.feature__heading {
    margin: 1rem 0;
}

.feature__image {
    width: 100%;
}

.feature__text {
    font-size: 2rem;
}

@media screen and (min-width: 768px) {
    .feature:nth-last-of-type(even) .feature__content {
        order: 2;
    }

    .feature__content {
        padding: 2rem;
    }
}

/* Navigation Bar */

.nav {
    background: var(--color-background);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    align-items: center;
}

.nav__list {
    width: 100%;
    margin: 0;
}

.nav__item {
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #222;
    font-size: 1.5rem;
}

.nav__item>a {
    text-transform: uppercase;
    color: var(--color-primary);
    transition: color 0.3s;
}

.nav__item>a:hover {
    color: var(--color-accent);
}

.nav__toggler {
    opacity: 0.5;
    transition: box-shadow 0.15s;
    cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 3px;
}

@media screen and (min-width: 1024px) {
    .nav__toggler {
        display: none;
    }

    .nav__list {
        width: auto;
        display: flex;
        font-size: 1.6rem;
        max-height: 100%;
        opacity: 1;
    }

    .nav__item {
        border: 0;
    }


}

/* Block Hero-Main */

.hero-main {
    background-image: url('../images/ocean.jpg');
    /* Fallback for browsers that do not support image-set or WebP */
    background-image: image-set(url('../images/ocean.webp') 1x,
            /* Standard WebP image */
            url('../images/ocean@2x.webp') 2x,
            /* Retina WebP image */
            url('../images/ocean.jpg') 1x,
            /* Fallback JPG image */
            url('../images/ocean@2x.jpg') 2x
            /* Fallback Retina JPG image */
        );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: var(--border-radius-block);
    margin: 2rem;
    text-align: center;
    box-shadow: 0 0 50px 5px var(--color-border);
    height: 180px;
    overflow: hidden;
}

.hero-main .block__heading {
    font-size: 3rem;
    margin: 1rem 2rem;
}

@media screen and (min-width: 768px) {
    .hero-main {
        background-position: center 40%;
        margin: 0;
    }

    .hero-main .block__heading {
        font-size: 4rem;
    }
}

@media screen and (min-width: 1024px) {
    .hero-main {
        height: 220px;
    }

    .hero-main .block__heading {
        margin: 2rem;
    }
}

/* Introduction Block */

.introduction {
    padding-top: 0;
}

.introduction__heading {
    font-size: 2.5rem;
    margin: 2rem;
}

.introduction__text {
    font-size: 2rem;
    margin: 2rem 0;
}

.introduction .feature {
    margin-top: 0;
}

@media screen and (min-width: 768px) {
    .introduction .block__header {
        margin: 2rem;
    }
}

/* Block About */

.about {
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    padding: 2rem 1.5rem;
}

.about .quote {
    font-size: 2rem;
    margin: 2rem;
}

.about .quote__author {
    font-size: 1.8rem;
}

.about .quote__organization {
    font-size: 1.6rem;
}

.about__heading {
    text-align: center;
    margin: 2rem;
}

.about__image {
    position: relative;
    display: block;
    height: auto;
    max-width: 60%;
    margin: 0 auto;
}

.about__image>img {
    display: block;
    width: 100%;
    height: auto;
}

.about .media {
    margin-bottom: 0;
}

.about__image>.icon-container {
    position: absolute;
    top: 3rem;
    right: -32px;
}

@media screen and (min-width: 768px) {
    .about .quote {
        font-size: 2rem;
    }

    .about .quote__author {
        font-size: 2.4rem;
    }

    .about .quote__organization {
        font-size: 1.8rem;
    }

    .about__image {
        max-width: 60%;
        align-self: center;
    }
}

@media screen and (min-width: 1024px) {

    .about .quote {
        font-size: 2.2rem;
    }

    .about .quote__author {
        font-size: 2.4rem;
    }

    .about .quote__organization {
        font-size: 2rem;
    }

    .about__image {
        max-width: 45%;
    }
}

/* Block Hero-Quote */

.hero-quote {
    background-image: url('../images/leaves.jpg');
    /* Fallback for browsers that do not support image-set or WebP */
    background-image: image-set(url('../images/leaves.webp') 1x,
            /* Standard WebP image */
            url('../images/leaves@2x.webp') 2x,
            /* Retina WebP image */
            url('../images/leaves.jpg') 1x,
            /* Fallback JPG image */
            url('../images/leaves@2x.jpg') 2x
            /* Fallback Retina JPG image */
        );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 30%;
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    height: 180px;
    overflow: hidden;
}

.block.container.hero:nth-of-type(even) {
    padding-left: 5rem;
    padding-right: 5rem;
}

.hero-quote .quote {
    font-size: 2rem;
    margin: 0rem 4rem;
    text-align: right;
    padding-top: 2rem;
}

.hero-quote .quote__text {
    margin: 0;
}

.hero-quote .quote__author {
    font-size: 2rem;
}

.hero-quote .media {
    justify-content: flex-end;
}

@media screen and (min-width: 768px) {
    .hero-quote .quote {
        margin: 0rem 20rem;
        padding-top: 1rem;
    }

    .hero-quote .quote__text {
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .hero-quote {
        height: 220px;
    }

    .hero-quote .quote__text {
        font-size: 3rem;
    }

    .hero-quote .quote__author {
        font-size: 2.5rem;
    }

    .hero-quote .quote {
        margin: 0rem 25rem;
        padding-top: 2rem;
    }
}

/* Block Appointment */

.appointment {
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    padding: 2rem 1.5rem;
    margin-bottom: 4rem;
}

.appointment .feature__heading {
    font-size: 2.2rem;
}

.appointment .feature__content>p {
    font-size: 1.8rem;
}

.appointment .feature__image-horizontal,
.appointment .featue__image-vertical {
    max-width: 240px;
    justify-self: center;
}

.appointment .feature__content>button {
    margin-top: 2rem;
    width: 70%;
}

@media screen and (min-width: 768px) {
    .appointment .feature__heading {
        font-size: 2.4rem;
    }

    .appointment .feature__content>p {
        font-size: 2rem;
    }

    .appointment .feature__content>a {
        font-size: 2rem;
    }

    .appointment .feature__image-horizontal {
        align-self: center;
        max-width: 340px;
    }
}


/* Block Hero-Main */

.hero-osteopathie {
    background-image: url('../images/plant.jpg');
    /* Fallback for browsers that do not support image-set or WebP */
    background-image: image-set(url('../images/plant.webp') 1x,
            /* Standard WebP image */
            url('../images/plant@2x.webp') 2x,
            /* Retina WebP image */
            url('../images/plant.jpg') 1x,
            /* Fallback JPG image */
            url('../images/plant@2x.jpg') 2x
            /* Fallback Retina JPG image */
        );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 95%;
    border-radius: var(--border-radius-block);
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 2rem;
    height: 130px;
    box-shadow: 0 0 50px 5px var(--color-border);
    overflow: hidden;
}

.hero-osteopathie .block__heading {
    font-size: 3rem;
    text-align: right;
    margin: 2rem;
    margin-top: 4rem;
}

@media screen and (min-width: 768px) {
    .hero-osteopathie {
        background-position: center 60%;
        height: 200px;
        margin: 2rem;
    }

    .hero-osteopathie .block__heading {
        font-size: 4rem;
        margin: 6rem 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .hero-osteopathie {
        background-position: center 60%;
        height: 300px;
    }

    .hero-osteopathie .block__heading {
        margin: auto 6rem;
        align-self: center;
    }
}

/* Osteopathie Block */

.block.container.osteopathie {
    padding-top: 0;
}

.osteopathie__heading {
    font-size: 2.4rem;
    margin: 2rem;
}

.osteopathie__text {
    font-size: 2rem;
    margin: 2rem;
}

.osteopathie .block__header {
    margin-bottom: 2rem;
}

.osteopathie .feature {
    margin-top: 0;
    gap: 0;
}

.osteopathie .feature__heading {
    font-size: 2.2rem;
}

.osteopathie .feature__content {
    padding: 2rem;
}

.osteopathie .feature__content>p {
    font-size: 2rem;
}

.osteopathie .feature__image-horizontal {
    width: 60%;
    margin: 0 6rem;
    box-shadow: 0 0 50px 5px var(--color-border);
}

.osteopathie .feature-block .feature__content {
    padding-top: 0;
}

@media screen and (min-width: 768px) {
    .osteopathie .block__header {
        margin: 2rem;
    }

    .osteopathie .feature {
        align-items: center;
        margin-top: 4rem;
    }

    .osteopathie .feature:last-of-type picture {
        order: 2;
    }

    .osteopathie .feature__content {
        padding: 1rem;
    }

    .osteopathie .feature-block .feature__content {
        padding: 1rem;
    }

    .osteopathie .featue__image-vertical {
        margin: 5rem;
    }

}

@media screen and (min-width: 1024px) {

    .osteopathie .block__header {
        margin: 2rem;
    }

    .osteopathie .feature {
        align-items: center;
    }

    .osteopathie .feature {
        margin-top: 4rem;
    }

    .osteopathie .feature:last-of-type picture {
        order: 2;
    }

    .osteopathie .feature__content {
        padding: 1rem;
    }

    .osteopathie .feature-block .feature__content {
        padding: 1rem;
    }

    .osteopathie .feature__image-horizontal:first-of-type {
        width: 50%;
        margin: 2rem 10rem;
    }

    .osteopathie .feature-block .feature__content {
        padding-top: 4rem;
    }
}

/* Ablauf-Kosten Block */

.block.container.ablauf-kosten {
    padding: 0;
}

.ablauf-kosten .block__header {
    margin-bottom: 2rem;
}

.ablauf-kosten .block__image {
    align-self: center;
}

.ablauf-kosten .feature__image-horizontal {
    width: 100%;
    box-shadow: 0 0 50px 5px var(--color-border);
}

.ablauf-kosten .list__item-text {
    font-size: 2rem;
}

@media screen and (min-width: 768px) {
    .ablauf-kosten .grid--1x2 {
        grid-template-columns: 40% 60%;
    }

    .ablauf-kosten .block__header {
        margin: 2rem;
    }

    .ablauf-kosten .feature__image-horizontal {
        width: 85%;
    }
}

@media screen and (min-width: 1024px) {

    .osteopathie .block__header {
        margin: 2rem;
    }

    .osteopathie .feature {
        align-items: center;
    }

    .osteopathie .feature {
        margin-top: 4rem;
    }

    .osteopathie .feature__content {
        padding: 1rem;
    }

    .osteopathie .feature-block .feature__content {
        padding: 1rem;
    }

    .osteopathie .feature-block .feature__content {
        padding-top: 4rem;
    }
}

/* Block Hero-Ablauf-Kosten */

.hero-ablauf-kosten {
    background-image: url('../images/clay.jpg');
    /* Fallback for browsers that do not support image-set or WebP */
    background-image: image-set(url('../images/clay.webp') 1x,
            /* Standard WebP image */
            url('../images/clay@2x.webp') 2x,
            /* Retina WebP image */
            url('../images/clay.jpg') 1x,
            /* Fallback JPG image */
            url('../images/clay@2x.jpg') 2x
            /* Fallback Retina JPG image */
        );
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    overflow: hidden;
}

.hero-ablauf-kosten .block__heading {
    font-size: 3rem;
    text-align: right;
    margin: 2rem;
    margin-top: 4rem;
}

.hero-ablauf-kosten>p {
    color: var(--color-body-darker);
    font-size: 2rem;
}

@media screen and (min-width: 768px) {

    .hero-ablauf-kosten {
        text-align: left;
    }

    .hero-ablauf-kosten .feature__heading {
        margin: 1rem 0;
    }

    .hero-ablauf-kosten.feature__content {
        padding: 4rem;
    }
}

@media screen and (min-width: 1024px) {
    .hero-ablauf-kosten {
        background-position: center 60%;
        height: 300px;
    }

    .hero-ablauf-kosten .block__heading {
        margin: auto 6rem;
        align-self: center;
    }
}

/* Block Costs */

.costs .block__header {
    margin-bottom: 0.5;
}

.costs .list__item {
    margin-bottom: 2rem;
    font-size: 2rem;
}

/* About Me */

.about-me {
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    padding: 2rem 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.about-me__image {
    position: relative;
    display: block;
    height: auto;
    max-width: 90%;
    margin: 0 auto;
}

.about-me__image>img {
    display: block;
    width: 100%;
    height: auto;
}

.about-me__heading {
    text-align: center;
    margin: 2rem;
}

.about-me__content {
    font-size: 2rem;
    margin: 2rem;
}

.about-me__header {
    margin-bottom: 2rem;
}

.about-me__body {
    font-size: 2rem;
}

.about-me .media {
    margin-bottom: 0;
}

.about-me .quote__author {
    font-size: 1.8rem;
}

.about-me .quote__organization {
    font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
    .about-me .quote {
        font-size: 2rem;
    }

    .about-me .quote__author {
        font-size: 2.4rem;
    }

    .about-me .quote__organization {
        font-size: 1.8rem;
    }

    .about-me__image {
        max-width: 80%;
        align-self: center;
    }
}

@media screen and (min-width: 1024px) {

    .about-me .grid--1x2 {
        grid-template-columns: 40% 60%;
    }

    .about-me .quote {
        font-size: 2.2rem;
    }

    .about-me .quote__author {
        font-size: 2.4rem;
    }

    .about-me .quote__organization {
        font-size: 2rem;
    }

    .about-me__image {
        max-width: 70%;
    }
}

/* Vita */

.vita {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem;
}

.vita__header>h3 {
    font-size: 2.4rem;
}

.vita__body {
    font-size: 2rem;
}

@media screen and (min-width: 768px) {

    .vita {
        margin: 0 10rem;
    }
}

@media screen and (min-width: 1024px) {

    .vita {
        margin: 0 30rem;
    }
}

/* book-me */

.book-me {
    padding-top: 4rem;
    padding-bottom: 0;
}

.hero-book-me {
    background-image: url('../images/beige.jpg');
    /* Fallback for browsers that do not support image-set or WebP */
    background-image: image-set(url('../images/beige.webp') 1x,
            /* Standard WebP image */
            url('../images/beige@2x.webp') 2x,
            /* Retina WebP image */
            url('../images/beige.jpg') 1x,
            /* Fallback JPG image */
            url('../images/beige@2x.jpg') 2x
            /* Fallback Retina JPG image */
        );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: var(--border-radius-block);
    box-shadow: 0 0 50px 5px var(--color-border);
    overflow: hidden;
}

.book-me .btn {
    margin: 2rem 4rem;
}

.book-me .btn i {
    margin-right: 1rem;
}


@media screen and (min-width: 768px) {

    .hero-book-me {
        background-position: center 40%;
    }

    .book-me .btn {
        margin: 6rem 2rem;
    }

    .hero-book-me.grid--1x3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1024px) {

    .hero-book-me {
        background-position: center 30%;
    }

    .book-me .btn {
        margin: 10rem 4rem;
    }
}

/*  Contact */

.contact {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.contact__heading {
    text-align: left;
    font-size: 2.6rem;
}

.contact__text {
    text-align: left;
    font-size: 2rem;
}

.finde-me .block__header {
    text-align: left;
    margin-bottom: 0;
}

.finde-me {
    font-size: 2rem;
}

.block.container.maps {
    padding-top: 0;
    padding-bottom: 4rem;
}

.map {
    width: 100%;
    height: 60vh;
    border: none;
}

@media screen and (min-width: 1024px) {

    .contact {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

/* Footer */

.footer {
    background: #232323;
}

.footer a {
    color: #777;
    transition: color 0.3s;
}

.footer a:hover {
    color: #fff;
}

.footer__section {
    padding: 2rem;
    border-bottom: 1px solid #393939;
}

.footer__section .list {
    margin: 0;
}

.footer__heading {
    text-transform: uppercase;
    font-weight: 600;
}

.footer__brand {
    margin-top: 5rem;
    text-align: center;
}

.footer__brand img {
    width: 100%;
    max-width: 100px;
}

.footer__copyright {
    font-size: 2rem;
    color: #fff;
    opacity: 0.3;
}

@media screen and (min-width: 768px) {
    .footer__sections {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    .footer .collapsible__chevron {
        display: none;
    }

    .footer .collapsible__content {
        opacity: 1;
        max-height: 100%;
    }

    .footer__brand {
        margin-top: 1rem;
        order: -1;
    }

    .footer__copyright {
        font-size: 1.5rem;
    }

    .footer__section {
        border: 0;
    }

    .footer__heading {
        font-size: 1.6rem
    }

    .footer__section {
        font-size: 2rem;
    }
}